
.contact-header {
  background: #fff;
  color: #000;
  a.btn {
    text-transform: none;
    text-decoration: none;
  }
}

.contactform {
  background: #333;
  .step1, .step2 {
    padding: 25px 35px;
    @media (max-width: $screen-xs-max) {
      padding: 25px 15px;
    }
  }
  h2 {
    margin-top: 0;
  }
}
.contactform-close {
  line-height: 35px;
  margin-right: -35px;
  background: #8DA4D2;
  color: #fff;

  @media (max-width: $screen-xs-max) {
    margin-right: -15px;
  }
}



.contact-table, .contact-header {
  padding: 25px 35px !important;
  @media (max-width: $screen-xs-max) {
    padding: 25px 15px !important;
  }
}
.contact-table > .row {
  margin-bottom: 25px;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
    margin-top: 25px;
    border-top: 1px solid #444;
    padding-top: 25px;
  }
}

@media (max-width: $screen-xs-max) {
  .contact-table h3, .contact-table .h3 {
    font-size: 28px;
  }
  .contact-table b.country {
    margin-left: -15px;
  }
  .contact-table .name {
    margin-left: -53px;
    padding-top: 12px;
  }
}



