
.meta {
  color: #aaa
}

.modal-close {
  background: $primary;
  color: #fff;
  float: right;
  height: 37px;
  width: 37px;
  line-height: 30px;
  border: 0;
  margin-top: -14px;
  margin-right: -15px;
}

.modal .content {
  h2 {
    margin-bottom: 35px;
  }
  hr {
    margin-top: 30px;
    margin-left: -40px;
    margin-right: -40px;
  }
}

.breadcrumb {
  background: #333;
  padding: 10px 25px;
  margin-bottom: 0;
  z-index: 600;
  position: relative;

  @media (max-width: $screen-xs-max) {
    padding: 10px 13px;
  }
}

.breadcrumb>li {
  line-height: 13px;
  font-size: 13px;
}
.breadcrumb>li+li:before {
  font-size: 13px;
  font-family: 'Glyphicons Halflings';
  content: "\e258";
  padding: 0 6px 0 2px;
  color: #9C9C9C;
  position: relative;
  top: 2px;
}
.breadcrumb a, .breadcrumb span {
  display: inline-block;
  vertical-align: text-bottom;
}
.breadcrumb a {
  color: #828282;
}
.breadcrumb>.active {
  color: #fff;
}

.block {
  margin-bottom: 13px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 25px;
  }
}

.disclaimer {
  margin-top: 15px;
  @include clearfix();

  .star {
    float: left;
    width: 10px;
    font-size: 18px;
    line-height: 25px;
  }
  .text {
    font-size: 12px;
    margin-left: 10px;
  }
}

.banner {
  max-height: 480px;
  overflow: hidden;
  position: relative;
  img {
    min-width: 100%;
    max-width: 100%;
  }
  .watermark {
    top: 0;
    z-index: 1;
    position: absolute;
  }
  .caption {
    font-family: 'Abel', sans-serif;
    z-index: 1;
    position: absolute;
    bottom: 30px;
    left: 25px;
    font-size: 40px;
    font-weight: 700;
    color: #fff;

    @media (max-width: $screen-xs-max) {
      font-size: 20px;
      left: 13px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 30px;
    }
  }
}

.banner-products {
  max-height: 375px;
}

.banner-category {
  .caption {
    top: auto;
    bottom: 8%;
    left: 3%;
  }
}
