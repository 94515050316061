
body {
  background: #333 url('/design/img/jts/bg.gif');
  color: #fff;
  line-height: 1.5em;

  font-family: "Myriad Pro", "Helvetica","Arial","Verdana", "Microsoft JhengHei","Microsoft Sans Serif", sans-serif;
  font-weight: 200;
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.25em;
  margin-bottom: 0.25em;
  font-family: 'Abel', sans-serif;
}

a {
  color: $primary;
  text-decoration: underline;

  .img-idle {
    display: inline-block;
  }
  .img-over {
    display: none;
  }
}
a:hover {
  color: $primary;

  .img-idle {
    display: none;
  }
  .img-over {
    display: inline-block;
  }
}

.fce-textmedia {
	margin-bottom: 50px;
}


.form-control {
  display: block;
  width: 100%;
  height: 36px;
  padding: 6px 9px;
}

.btn {
  text-transform: uppercase;
  border-width: 2px;
  font-family: 'Abel', sans-serif;
  color: #000;
  padding: 7px 12px;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
  box-shadow: none;
  background: transparent;
  border-color: $primary;
  color: $primary;
}

.btn-primary:hover {
  background: $primary;
  border-color: $primary;
  color: #fff;
}

.btn-default,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus {
  background: transparent;
  border-color: #fff;
  color: #fff;
  box-shadow: none;
}

.btn-default:hover {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.btn-grey,
.btn-grey:focus,
.btn-grey:active,
.btn-grey:active:focus {
  background: transparent;
  border-color: #aaa;
  color: #aaa;
  box-shadow: none;
}
.btn-grey:hover {
  background: #aaa;
  border-color: #aaa;
  color: #000;
}

.btn-white,
.btn-white:focus,
.btn-white:active,
.btn-white:active:focus {
  background: transparent;
  border-color: #fff;
  color: #fff;
  box-shadow: none;
}
.btn-white:hover {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.caret {
  top: 1px;
  border: none;
  width: 16px;
  height: 16px;
  font-size: 12px;
  position: relative;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: "\e259";
  }
}

.wrapper, .shell, .block-container {
  padding: 0 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
  }
}

a.download {
  color: #fff;
  display: inline-block;
  padding: 15px 15px 15px 0;
  text-decoration: none;
  &:before {
    background: $primary none repeat scroll 0 0;
    color: #fff;
    content: "\e025";
    display: inline-block;
    font-family: "Glyphicons Halflings";
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    text-align: center;
    width: 30px;
  }
}


input[type=radio], input[type=checkbox] {
  display:none;
}

input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 7px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}
input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 7px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}

input[type=radio]:checked + label:before {
  content: "\2022"; /* Bullet */
  color: $brand-primary;
  font-size:1.8em;
  text-align:center;
  line-height:19px;
}
input[type=checkbox]:checked + label:before {
  content: "\2714"; /* Tick */
  color: $brand-primary;
  text-align:center;
  line-height: 27px;
}

input[type=checkbox] + label {
  padding-left: 0;
}
