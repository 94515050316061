
.button {
  font-family: 'Abel', sans-serif;
  background: #eee;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.1;
  top: 25px;
  right: 25px;
}
.button-inline {
  position: static;
  top: auto;
  right: auto;

}
.button span {
  display: table-cell;
  vertical-align: middle;
}
.button-black {
  background-color: #000;
  color: #fff;
}
.button-teal {
  background-color: $primary;
  color: #fff;
}
.button-orange {
  background-color: #FF4434;
  color: #fff;
}
.button {
  width: 100px;
  height: 100px;

  @media (max-width: $screen-xs-max) {
    font-size: 10px;
    top: 13px;
    right: 13px;
    width: 60px;
    height: 60px;
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 12px;
    width: 80px;
    height: 80px;
  }
}

.carousel {
  margin-top: 25px;

  .slide {
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      min-width: 100%;
    }
  }
  .watermark {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

  }
  .button {
    top: 25px;
    right: 25px;
    @media (max-width: $screen-xs-max) {
      top: 13px;
      right: 13px;
    }
  }

}
.carousel-indicators  {
  margin-bottom: 0;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
    bottom: 0px;
  }
}
.carousel-indicators li {
  border: 0;
  margin: 11px;
  background-color: rgba(255,255,255,0.2);
}
.carousel-indicators li.active {
  margin: 10px;
  background-color: rgba(255,255,255,0.5);
}

.carousel-control {
  position: absolute;
  height: 59px;
  top: 50%;
  bottom: auto;
  width: 50px;
  opacity: .5;
  filter: alpha(opacity=50);
  font-size: 44px;
  color: #707070;
  text-align: center;
  text-shadow: none;
  background-color: rgba(0,0,0,0.5);
  line-height: 71px;
}


.category-blocks {
  .item {
    display: block;
    padding: 0;
  }

  .item:first-of-type {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 15px;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    > .col-xs-12 {
      padding: 0;
    }
  }

  .image {
    position: relative;
    overflow: hidden;
  }

  .image img {
    display: inline-block;
    min-width: 100%;
    max-width: 100%;
    transition-duration: 0.4s;
  }

  .caption {
    bottom: -1px;
    font-family: 'Abel', sans-serif;
    font-size: 40px;
    position: absolute;
    background: rgba(0,0,0,0.6);
    padding: 35px 25px;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    @media (max-width: $screen-xs-max) {
      font-size: 20px;
      padding: 20px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 30px;
    }
  }

  .category-block:hover .image img {
    transform: scale(1.05);
  }
}


.jts .textbox-33 {
  overflow: hidden;
  a {
    overflow: hidden;
    position: relative;
    img {
      transition-duration: 0.4s;
    }
    &:hover img {
      transform: scale(1.1);
    }
  }
}