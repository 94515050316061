
#element-2306, #element-2307 {
  background: #efefef;
  padding-top: 25px;

  .ce-gallery {
    margin-bottom: 0;
  }
  .ce-row, .ce-textpic, .ce-gallery {
    overflow: visible;
  }
  img {
    transition: transform 0.2s ease;
    transform: scale(1);
  }
  a:hover img {
    transform: scale(1.1);
  }

  @media (max-width: 420px) {
    .ce-row {
      width: 100%;
    }
    .ce-column {
      width: 47%;
      float: left;
      min-height: 250px
    }
  }
}


#search-results-desktop {
  margin-top:50px;
}
.download-search.download-jts h2 {
  margin-bottom: 20px;
}
.jts .printorder .printorder-title {
  padding-left: 0px;
}



.support-title {
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
    padding-left: 15px;
  }
}

.support {
  color: #fff;
  background: #2D2D2D;
  margin-bottom: 1px;
  position: relative;

  .row {
    @media (min-width: $screen-sm-min) {
      display: table-row;
      .col-xs-6 {
        display: table-cell;
        float: none !important;
      }
    }

    @media (max-width: $screen-xs-max) {
      .eq-element {
        height: auto !important;
      }
    }

  }

  .contact-card {
    display: flex;
    margin-left: -15px;
    min-height: 147px;

    @media (max-width: $screen-xs-max) {
      display: block;
      margin: 0
    }
  }

  .contact-image {
    background: #000;

    @media (max-width: $screen-xs-max) {
    img { min-width: 100%; }
    }
  }

  .contact-cell {
    @media (min-width: $screen-sm-min) {
      flex-grow: 1;
      vertical-align: top;
    }
  }

  .contact-info {
    background: #000;
    color: #fff;
    padding: 20px;
    transition: margin 300ms ease-out;

    @media (max-width: $screen-xs-max) {
      font-size: 13px;
      padding: 5px 13px
    }

    .name {
      text-transform: uppercase;
      font-weight: 700;
    }
    li {
      padding-top: 10px;
      white-space: nowrap;
    }
    a {
      color: #fff;
    }

    &:hover {
      margin-top: -10px;
      background: $primary;
      color: #fff;
    }
  }

  .about {
    font-size: 14px;
    padding-top: 20px;
    line-height: 1.4;

    @media (max-width: $screen-xs-max) {
      padding: 13px;
    }
  }


  @media (max-width: $screen-xs-max) {
    .contact-card {
      .contact-info {
        transition: none;
      }
      .contact-info:hover {
        margin-top: 0;
        background: #000;
      }
    }
  }

  .contact-card.no-hover {
    .contact-info {
      transition: none;
    }
    .contact-info:hover {
      margin-top: 0;
      background: #000;
    }
  }


  .support-contact-form {
    background: #2d2d2d;
    padding: 25px 13px 16px 13px
  }
}


.download-search {
  margin-top: 40px;

  form {
    background: #fff;
    padding-top: 35px;
    padding-bottom: 35px;
  }


  .downloads-mobile {
    padding: 25px;
    .h4 {
      margin-bottom: 15px;
    }
    li {
      padding-bottom: 10px;
    }
    img {
      margin-right: 10px;
    }
  }

  .downloads {

    .header {
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 2px solid #8AA3D4;

      .row > div {
        padding-bottom: 4px;
        color: #666;
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }
    }

    .model {
      border-top: 0;
      margin-bottom: 0;

      img {
        height: 22px;
        padding-right: 5px;
      }

      a {
        color: #fff;
        font-size: 13px;
      }

      .row > div {
        padding-bottom: 5px;
        padding-top: 5px;
        border-bottom: 1px solid #3E4149;
        white-space: nowrap;
        height: 35px;
        line-height: 22px
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }
    }

  }
}
