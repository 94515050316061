
.favorites-empty .badge {
  display: none;
}

.toggle-favorites .badge {
  position: absolute;
  z-index: 10;
  margin-left: -9px;
  margin-top: -8px;
  background: $primary;
  font-weight: normal;
  padding: 4px 6px;
}

#merkliste {
  color: #000;
}
#merkliste .modal-header .modal-title {
  color: #333;
}
#merkliste #merkliste_form, #merkliste #merkliste_intro {
  color: #000;
}

#merkliste #merkliste_products .item .remove-button .btn {
  border: 1px solid #E8E8E8;
  background: #E8E8E8;
  color: #868686;
}

#merkliste_products .corner {
  left: 14px;
  height: 30px;
  width: 51px;
  z-index: 100;
  top: 0;
  line-height: 17px;
}

#merkliste .modal-body .title {
  font-weight: bold;
}

#merkliste .close-form {
  color: #868686;
  font-size: 12px;
}

#merkliste .modal-header .modal-close {
  margin-top: -14px;
}