#mainnav .nav > li {
  > a[href*="webshop"],
  > a[href*="webshop"]:hover {
    background: rgb(138, 163, 212) !important;
    color: rgb(255, 255, 255) !important;
  }
}

// mini navigation (>break only)

#mininav {
  min-height: 50px;
  font-family: 'Abel', sans-serif;

  margin: 0;
  padding-right: 15px;
  padding-top: 5px;

  color: #9C9C9C;
  a { color: #9C9C9C; }

  .item {
    padding-left: 15px;
  }
  .lang .dropdown-menu {
    margin-right: 8px;
    margin-top: -18px;
    background: #222;
    min-width: 89px;
    a { color: #BCBCBC; text-decoration: none; }
    a:hover { background: #333; }
  }

  .phone img path {
    stroke: red;
  }

  display: none;
  @media (min-width: $grid-float-breakpoint) {
    display: block;
  }

}

// main navigation

#mainnav {
  margin-bottom: 0px;
  min-height: 25px;
  line-height: 20px;
  border: 0;
  font-family: 'Abel', sans-serif;

  @media (min-width: $grid-float-breakpoint) {
    .nav {
      float: right !important;
      margin-right: -12px;
    }
  }

  .navbar-brand {
    padding: 0;
    padding-left: 25px;
    margin-top: -15px;
    > img {
      height: 50px;
      transition: height 200ms ease-out;
    }
  }

  .navbar-toggle {
    background: transparent;
    margin: 0;
    font-size: 25px;
    padding: 15px;
    padding-bottom: 18px;
    border: 0;
    color: #fff;
  }

  .menu-close {
    padding: 0 1px;
  }

  .nav>li>a {
    padding: 15px 20px;
    font-size: 19px;
    color: #fff;
    border: 0;
    text-decoration: none;
    letter-spacing: 0.1em;
  }

  .nav>li>a:hover,
  .nav>li>a:focus,
  .nav>li.active>a {
    color: $primary !important;
    background-color: transparent;
  }
  .nav>li.active>a {
    @include box-shadow(0 0 8px 3px rgba(0,0,0,.2));
  }

  .nav>li.search>a {
    overflow: hidden;
    line-height: 18px;
    font-size: 18px;
  }

  .nav>li.merkliste>a {
    padding-top: 15px;
    padding-bottom: 13px;

    img {vertical-align: bottom;}
  }

  .dropdown-menu {
    margin-left: -15px;
    padding: 15px;
    padding-top: 0;
    border: 0;
    background: #000;

    a {
      text-decoration: none
    }
  }
  .dropdown-menu>li {
    border-bottom: 0;
  }
  .dropdown-menu>li:last-of-type {
    border-bottom: 0;
  }
  .dropdown-menu>li>a {
    text-decoration: none;
    letter-spacing: 0.1em;
    font-size: 17px;
    padding: 15px 20px;
    color: #fff;
  }
  .dropdown-menu>li>a:hover {
    background: transparent;
    color: $primary !important;
    font-weight: 700;
  }

  span.caret {
    margin-left: 0;
    margin-top: 3px;
    float: right;
  }
  .navbar-collapse {
    border-top: 0;
  }
  .navbar-nav {
    margin: 0 -12px;
  }

  .navbar-header {
    .toggle-search, .toggle-favorites {
      display: none;
    }
  }


  .dropdown-toogle {
    display: none;
  }

  @media (max-width: $grid-float-breakpoint-max) {

    .dropdown-toogle {
      position: absolute;
      right: 0;
      top: 0;
      outline: 0;
      width: 48px;
      height: 50px;
      float: right;
      background: rgb(0, 0, 0);
      color: rgb(136, 161, 208);
      border: 0;
      padding: 0;
      border-left: 1px solid rgb(204, 204, 204);
      border-bottom: 1px solid rgb(204, 204, 204);
      display: flex;
      justify-content: center;
      align-items: center;
      .caret {
        float: none;
      }
    }
    .open .dropdown-toogle {
      .caret {
        transform: rotate(180deg);
        margin-top: -3px;
      }
    }
    .nav .open > a {
      border-bottom: 1px solid rgb(204, 204, 204);
    }



    .navbar-brand {
      padding-left: 23px;
      margin-top: 10px;
      height: auto;
      img {
        height: 35px;
      }
    }

    .caret {
      display: block !important;
    }

    .search, .merkliste {
      display: none !important;
    }

    .navbar-header {

      .navbar-toggle {
        padding: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 19px;
        padding-right: 21px;
        text-align: center;
        margin: 0;
      }

      .toggle-search, .toggle-favorites {
        display: block;
        position: relative;
        float: right;
        display: block;
        border-left: 1px solid #262626;
        text-align: center;
        width: 60px;
      }

      .toggle-favorites {
        color: #fff;
        border-right: 1px solid #262626;
        padding-top: 15px;
        padding-bottom: 17px;
      }

      .toggle-search {
        color: #fff;
        font-size: 20px;
        line-height: 1;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .nav>li {
      border-bottom: 1px solid $black20;
    }
    .nav>li.black20bg {
      background: #fff;
      color: #000;
      a {
        color: #000;
      }
    }

    .nav>li>a {
      padding-left: 25px;
    }
    .dropdown-menu {
      margin-left: 25px;
      background: #fff;
    }
    li.visible-xs {
      display: block !important;
    }

  }

  @media (min-width: $grid-float-breakpoint) {
    li.dropdown:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
}

// Helper classes

.visible-menu-open {
  display: none;
}
.visible-menu-closed {
  display: block;
}
body.menu-visible {
  .visible-menu-open {
    display: block;
  }
  .visible-menu-closed {
    display: none;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    #mainnav .navbar-toggle {
      background: $primary;
      color: #fff;

      padding-right: 20px;
      padding-top: 14px;
      padding-bottom: 16px;
    }
  }
}

body.search-visible {
  @media (max-width: $grid-float-breakpoint-max) {
    #mainnav .navbar-header .toggle-search {
      color: $primary;
    }
  }
}



// fixed header
@media (max-width: $grid-float-breakpoint-max) {
  body {
    padding-top: 54px;
  }
  #app {
    position: relative;
    max-width: 100%;
    #header {
      background: transparent;
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1010;

      .navbar {
        background: #000;
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  body.scrolling {
    padding-top: 99px;
    #mininav {
      display: none;
    }
    #mainnav {
      .navbar-brand {
        margin-top: 9px;
        height: 30px;
        >img {
          height: 30px;
        }
      }
    }
    #app {
      position: relative;
      max-width: 100%;
      #header {
        background: transparent;
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1010;
        .navbar {
          background: #000;
        }
      }
    }
    @media (min-width: 1298px) {
      #app #header {
        width: 1280px;
      }
    }
  }
}


@media (min-width: $grid-float-breakpoint) {
  .image-menu {
    top: 51px;
    position: absolute;
    z-index: 500;
    width: 660px;
    height: 225px;
    overflow: hidden;

    .image {
      width: 300px;
      height: 300px;
      text-align: right;
      overflow: hidden;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      img {
        height: 100%;
        max-height: 100%;
      }
      &:before {
        display: block;
        position: absolute;
        background: url('/design/img/jts/menu-blackfade.png');
        width: 300px;
        height: 100%;
        content: " ";
      }
    }

    .image.first {
      z-index: 1;
    }

    li {
      .image { display: none; }
      .image.first { display: block; }
    }
    li:hover {
      .image { display: block; }
    }
  }


}

@media (max-width: $grid-float-breakpoint-max) {
  .image-menu .image {
    display: none;
  }
}
