#marken {
  background: #F2F2F3;
  margin-top: 75px;
  margin-bottom: 25px;
  padding: 0 25px;
  height: 96px;

  .slogan {
    padding-top: 25px;
    font-size: 13px;
    color: #444
  }

  .logos {
    @include clearfix();
    .row {
      margin: 0 -4px;
    }
    .row > div {
      padding: 0 2px;
    }
    a {
      text-align: center;
      line-height: 1;
      position: relative;
      min-width: 100%;
      max-width: 206px;
      float: left;
      display: block;
      background: #EBEBEC;
      color: #575757;
      height: 96px;
      img {
        display: block;
        margin: 0 auto;
        transition-duration: 0.4s;
      }
      span {
        font-size: 9px;
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
      }
      &:hover img {
        transform: scale(1.1);
      }
    }

    // adjustments
    .img img {
      height: 40px;
    }
    .monacor {
      padding-top: 13px;
    }
    .img {
      padding-top: 20px;
    }
    .jts {
      padding-top: 30px;
    }
  }


  @media (max-width: $screen-xs-max) {
    margin-top: 40px;
    height: auto;
    padding: 0;

    .slogan {
      font-size: 14px;
      text-align: center;
      padding: 15px 30px;
    }

    .logos a {
      height: 80px;
    }
    .logos a span {
      display: none;
    }
  }
}