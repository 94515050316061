#products {
  .description {
    padding: 25px;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
      padding-bottom: 0;
    }
  }
}

.search-others-intro {
  padding-top: 20px;
  font-weight: bold;
}
.brand-box {
  margin-top: 20px;

  .brand-logo {
    background: #333;
    padding: 15px;
    margin-bottom: 0;
    height: 60px;
  }
}

.filter {
  background: #fff;
  color: #000;
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }

  h3, .h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 30px;
  }

  .h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .range-filter {
    margin-bottom: 15px;
    color: #000;

    .slider {
      width: 100% !important;
    }
    .slider-handle {
      background-image: none;
      background-color: $primary;
      opacity: 1
    }
    .slider-track {
      background-image: none;
      background-color: #eee;
    }
    .slider-selection {
      background-image: none;
      background-color: #ccc;
    }
    .range-value {
      width: 30%;
      display: inline-block;
    }
    .range-values {
      padding-top: 20px;
      @include clearfix();
      text-align: center;
      line-height: 30px;
    }
    .range-value-from {
      float: left;
    }
    .range-value-to {
      float: right;
    }
  }


  .checkbox-filter {
    li {
      padding-bottom: 10px;
    }

    label {
      font-weight: normal;
      cursor: pointer;
      color: #000;
    }
  }


  input[type=radio], input[type=checkbox] {
    display:none;
  }

  input[type=radio] + label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right: 7px;
    color: $brand-primary;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
  input[type=checkbox] + label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right: 7px;
    color: $brand-primary;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
  }

  input[type=radio]:checked + label:before {
    content: "\2022"; /* Bullet */
    color: $brand-primary;
    font-size:1.8em;
    text-align:center;
    line-height:19px;
  }
  input[type=checkbox]:checked + label:before {
    content: "\2714"; /* Tick */
    color: $brand-primary;
    text-align:center;
    line-height: 27px;
  }
}

.product-blocks {

  background: #E6E6E6;
  color: #000;

  .product-blocks-header {
    padding: 24px 0 20px 4px;

    @media (max-width: $screen-xs-max) {
      padding: 13px 0;
    }


    .product-sort {
      text-align: right;
      .dropdown-toggle {
        color: #000;
        font-size: 17px;
      }
      .dropdown-menu {
        right: 0;
        left: auto;
      }
    }
    .product-label {
      padding-top: 10px;
      font-size: 17px;
      margin-left: -1px;
    }
  }

  .product-blocks-items {
    padding: 25px;
    padding-top: 0;

    @media (max-width: $screen-xs-max) {
      padding: 13px;
    }


    .listitem {
      .item {
        display: block;
        position: relative;
        background: #fff;
        margin-bottom: 5px;
        padding: 15px;
        text-decoration: none;
        color: #000;

        &:hover {
          color: #000;
        }
      }
      .title {
        font-weight: bold;
        color: $brand-primary;
        margin-top: 0;

        @media (max-width: $screen-xs-max) {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .meta {
        font-size: 13px;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
        }
      }

      .teaser {
        font-size: 13px;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
        }
      }
      .price {
        text-align: right;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
          padding-top: 10px;
        }
      }

      .image {
        height: 65px;
        text-align: center;

        @media (max-width: $screen-xs-max) {
          height: 140px;
        }

        img {
          display: inline-block;
          max-height: 100%;
        }
      }
    }


    .griditem {
      .item {
        display: block;
        background: #fff;
        position: relative;
        height: 100%;
        text-decoration: none;
        margin-bottom: 35px;
        @media (max-width: $screen-xs-max) {
          margin-bottom: 20px;
        }

        @media (min-width: $screen-sm-min) {
          transition: transform 150ms ease-out;
          z-index: 1;
        }
        &:hover {
          @media (min-width: $screen-sm-min) {
            transform: scale(1.1);
            z-index: 100;
          }
        }

      }

      .image {
        padding: 35px;
        padding-bottom: 0;

        @media (max-width: $screen-xs-max) {
          padding: 13px;
          padding-bottom: 0;
        }
        @media (max-width: $screen-sm-max) {
          padding: 20px;
        }

      }

      .image img {
        max-width: 100%;
        max-height: 100%;
        height: auto !important;
        width: auto !important;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .link {
        opacity: 0;

        background: $primary;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        text-align: center;
        text-transform: uppercase;

        transition: opacity 250ms ease-out;
      }

      .details {
        padding: 25px;
        color: #000;
        text-decoration: none;
        position: relative;
        min-height: 190px;

        @media (max-width: $screen-xs-max) {
          padding: 13px;
        }

        .title {
          font-weight: 700;
          margin: 0;
          font-size: 20px;
          margin-bottom: 3px;
        }

        .meta, .teaser {
          font-size: 13px;
        }

        .teaser {
          padding-bottom: 20px;
        }

        .previous {
          text-decoration: line-through;
          color: #ccc;
        }

        .price {
          font-family: 'Abel', sans-serif;
          position: absolute;
          bottom: 15px;
        }
      }
    }

    a:hover {
      text-decoration: none;
    }


    @media (min-width: $screen-sm-min) {
      a:hover .link {
        opacity: 1;
      }
    }

  }

}