.imagebanner {
  .imagebanner-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 500px;

    .imagebanner-caption {
      display: table;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .imagebanner-caption-text {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      font-size: 80px;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }
  .imagebanner-content {
    background: #262626;
    padding: 35px;

    .imagebanner-title {
      font-size: 40px;
      color: #fff;
      margin: 0 0 25px 0;
      text-transform: uppercase;
      width: 65%
    }
    .imagebanner-text {
      color: #fff;
      width: 65%
    }
  }

  @media (max-width: $screen-xs-max) {
    .imagebanner-image  {
      height: 200px;
    }
    .imagebanner-image .imagebanner-caption-text {
      font-size: 30px;
    }
    .imagebanner-content {
      padding: 20px;
    }
    .imagebanner-content .imagebanner-title {
      font-size: 20px;
      width: 100%
    }
    .imagebanner-content .imagebanner-text {
      font-size: 13px;
      width: 100%
    }
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .imagebanner-image  {
      height: 400px;
    }
    .imagebanner-content .imagebanner-title {
      font-size: 30px;
    }
  }

}