#category {
  .description {
    padding: 25px;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
      padding-bottom: 0;
    }
  }
}


.category-header {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  @media (max-width: $screen-xs-max) {
    height: 100px;
    background-position: top center;
  }
}
.category-header-image {
  height: 300px;
  background-repeat: no-repeat;
  background-position: left center;
  opacity: 0.3;

  @media (max-width: $screen-xs-max) {
    height: 100px;
    background-position: top center;
    background-size: cover;
  }
}
.category-header-slim {
  height: 150px;
  .category-header-image {
    height: 150px;
  }
}
.category-header-caption {
  position: absolute;
  bottom: 25px;
  left: 25px;
  margin: 0;
  font-weight: normal;
  color: #fff;

  @media (max-width: $screen-xs-max) {
    font-size: 20px;
    left: 13px;
    bottom: 25px;
  }
}




.caption-blocks {
  margin: 25px;

  .item {
    margin-bottom: 50px;

    a {
      display: block;
      position: relative;
      text-decoration: none;
    }
  }

  .image {
    min-height: 300px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .caption {
    position: absolute;
    background: rgba(0,0,0,0.6);
    padding: 25px;
    left: 0;
    right: 0;
    bottom: -1px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    text-decoration: none;
  }

  .item a:hover {
    .caption {
      background: $primary;
      color: #fff;
    }
  }

  @media (max-width: $screen-xs-max) {
    margin: 25px 13px;
    .item {
      margin-bottom: 25px;
    }
    .caption {
      padding: 15px;
    }
  }
}


.caption-sub-blocks {
  .item a {
    display: table;
    width: 100%;
  }
  .image {
    display: table-caption;
    background-size: auto 240px;
    background-color: #fff;
  }
  .caption {
    position: static;
    display: table-cell;
    vertical-align: middle;
  }

}


.corner {
  font-size: 13px;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  background: #000;
  color: #fff;
  padding: 7px 8px 5px 8px;
}

.corner-new {
  background: $primary;
  color: #fff;
}

@media (max-width: $screen-xs-max) {
  .corner {
    top: 10px;
    right: 10px;
  }
}