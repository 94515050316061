
#wrap {
  max-width: 1280px;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}

#app {
  background: #262626;
  overflow: hidden;

  #header {
    position: relative;
    background: #000;
    @include box-shadow(0 5px 8px -1px rgba(0,0,0,.2));
    min-width: 310px;
    margin: 0 auto;
  }

  #footer {
    background: #000;
  }

  #border {
    background: $primary;
    color: #fff;
  }
}