/* abel-regular - latin */
@font-face {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  src: url('/design/fonts/abel-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/design/fonts/abel-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/design/fonts/abel-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/design/fonts/abel-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/design/fonts/abel-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/design/fonts/abel-v18-latin-regular.svg#Abel') format('svg'); /* Legacy iOS */
}