.fce-pagelibrary_newsarchive {
  background: rgb(51, 51, 51);
  padding: 20px 0;

  .news-archive small {
    color: rgb(153, 153, 153);
  }
}

.news-headline {
  padding-top: 40px;
}

.newsletter {
  position: relative;
  padding: 30px 25px;
  @media (max-width: $screen-xs-max) {
    padding: 20px 13px;
  }
  background: #fff;
  color: #000;

  .about {
    font-family: 'Abel', sans-serif;
    font-size: 17px;
    line-height: 1.4;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
      margin-top: 0;
    }


    @media (max-width: $screen-xs-max) {
      padding-bottom: 20px;
    }
  }
  .form {
    .input {
      margin-bottom: 10px;
    }
  }
}

.articles {

  .item {
    position: relative;
    background: #eee;
    margin-bottom: 35px;
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }

    .row {
      height: 100%;
    }

    .image {
      margin-left: -1px;
      overflow: hidden;

      @media (max-width: $screen-sm-max) {
        height: 150px;
      }

      img {
        display: inline-block;
        max-width: 100%;
        min-width: 100%;
      }
    }

    .text {
      postion: relative;
      height: 100%;

      .headline {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 15px;
        text-transform: uppercase;

        a {color:#000}

        @media (max-width: $screen-sm-max) {
          margin-top: 13px;
        }
      }

      .content {
        color: #000;
        padding: 15px;
        padding-left: 0;
        @media (max-width: $screen-sm-max) {
          padding: 13px;
        }
      }
      .footer {
        padding-bottom: 15px;
        @media (max-width: $screen-sm-max) {
          padding: 13px;
        }
      }
    }




  }
}



.modal-close {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 14px;
}
.news-banner {
  position: relative;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}


.news-article {
  padding: 25px;
  header {
    padding-bottom: 15px;
  }


  h3, .h3 {
    margin-top: 0;
    font-size: 30px;
    color: #000;
  }

  time {
    color: #BABABA;
  }

  .news-backlink {
    padding-top: 25px;
  }
}

.news-article-text {
  img {
    width: 100% !important;
    height: auto !important;
  }

  line-height: 1.5;

  column-count: 2;
  column-gap: 25px;

  @media (max-width: $screen-xs-max) {
    column-count: 1;
  }
}

#content {
  .news-single {
    .article {
      .news-img-wrap {
        width: auto;
        max-width: 100%;
        margin: 0;
        float: none;
        background: transparent;
        padding-top: 25px;

        iframe {
          width: 100% !important;
        }
      }
      .news-img-wrap .viewer a {
        float: none;
        padding: 0;
        border: none;
      }

      .image {

        .viewer {
          text-align: center;

          img {
            margin: 0 auto;
            display: inline-block;

            @media (max-width: $screen-xs-max) {
              width: 100%;
            }
          }

          .item {
            padding: 0px;
            a {
              border: none;
              padding: 0;
            }
          }

          .carousel-indicators {
            bottom: -60px;
            li {
              border-color: #DEDEDE;
              background: #DEDEDE;
            }
            .active {
              background: #8D8D8D;
            }

            @media (max-width: $screen-sm-max) {
              bottom: 8px;
            }

            @media (min-width: $screen-sm-min) {
              display: none;
            }
          }

        }

        .images {
          padding-top: 15px;
          @include clearfix();
        }
        .badges {
          position: relative;
          padding-top: 10px;
        }

      }

      .thumbnails {
        position: relative;
        .thumbnail {
          float: left;
          cursor: pointer;
          border-color: #333;
          background: #000;
          padding: 5px;
          display: block;

          margin-right: 3px;
          margin-bottom: 3px;
          height: 80px;
          width: 96px;


          &:last-of-type {
            margin-right: 0;
          }

          img {
            max-height: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%)
          }

          .glyphicon-plus {
            margin-top: 21px;
            margin-left: 22px;
          }
        }
        .active {
          border-color: $brand-primary;
        }
      }

    }
  }
}
