#map_radius {
  padding-right: 0;
}

#haendlersuche {

  .searchform {
    background: transparent;
    margin-bottom: 20px;
    font-family: 'Abel', sans-serif;
    font-size: 17px;
    line-height: 1.4;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 25px;
    }

    form {
      margin: 0;
    }

    #map_productcategory {
      @media (max-width: $screen-xs-max) {
        margin-top: 15px;
      }
    }
  }

  .results {
    background: #fff;
    color: #000;


    padding: 30px 25px;
    @media (max-width: $screen-xs-max) {
      padding: 20px 13px;
    }

    h2 {
      font-size: 26px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 25px;


      @media (max-width: $screen-xs-max) {
        margin-top: 10px;
        padding-left: 0px;
      }
    }

    .item {
      display: block;
      background: #E6E6E6;
      padding: 15px;
      margin-bottom: 15px;
      color: #000;
      min-height: 92px;

      &:hover {
        background: #ddd;
        text-decoration: none;
      }

      .pin {
        width: 26px;
        float: left;
      }
      .haendler {
        display: block;
        margin-left: 40px;
      }
    }

  }

  .display {
    margin-top: 25px;

    @include clearfix();
    position: relative;

    .map-container {
      height: 450px;
    }

    .map {
      height: 100%;
      width: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: -5px 0 6px 0 rgba(0, 0, 0, 0.2);
      color: #000;

      @media (max-width: $screen-xs-max) {
        position: relative;
        bottom: auto;
        right: auto;
        width: 100%;
        background: #fff;
      }
    }

    .store {
      padding: 25px;

      .about {
        margin-bottom: 25px;
      }

      .name {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 20px;
      }

      .address {
        .pin {
          width: 26px;
          float: left;
        }
        address {
          font-family: 'Abel', sans-serif;
          display: block;
          margin-left: 40px;
        }
      }

      .mobile-contact {
        padding: 10px 0;
        a { font-size: 14px; overflow: hidden; text-decoration: none;
          background: #eee;
          color: #333;
        }
        a:hover { color: $primary; }
      }
    }

    @media (max-width: $screen-xs-max) {
      .results, .store {
        padding: 13px;
      }
      .store {
        .btn {
          width: 100%;
          height: 45px;
          line-height: 30px;
        }
        .link-phone {
          margin-bottom: 22px !important;
        }
        .address {
          .pin {
            display: none
          }
          address {
            margin-left: 0
          }
        }
      }
    }

  }

  &.such-layout-1 {

    .display .overlay {
      display: block;
      position: relative;
      height: auto;
      width: 100%;
      background: #333;
      color: #fff;
      border-bottom: 8px solid #8BA4D2;
    }
  }
}
