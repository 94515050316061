#footer {

  .links {
    padding: 25px;
    background: #333333;
    color: #fff;
    text-align: right;
    a {
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
    }
    .page-link {
      padding-right: 15px;
    }

    .pages {
      padding-top: 3px;
      display: inline-block;
      float: left;
    }

    .social {
      a {
        padding-left: 10px;
      }
    }
  }
  .contact {
    padding: 25px;
    background: #5E5E5E;
    color: #fff;

    .brand-info img {
      margin-top: 20px;
      max-width: 210px;
    }

    .contact-button {
      border-radius: 4px;
      background: $primary;
      color: #fff;
      text-align: center;
      margin-bottom: 9px;
      padding: 10px;
      white-space: nowrap;

      a { color: #fff; text-decoration: none; }
    }
  }
  .about {
    padding: 32px 25px;
    font-size: 13px;
    background: #666666;
    color: #fff;
    .related a {
      color: #fff;
    }
  }
  .border {
    padding: 12px 25px;
    line-height: 1;
    background: $primary;
    color: #fff;
    strong {
      padding-right: 15px;
    }
  }
}


@media (max-width: $screen-xs-max) {
  #footer {
    > div {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .links {
      column-count: 2;
      column-gap: 0px;

      padding-top: 20px;

      .social {
        float: none;
        padding-top: 15px;
        display: block;
        margin-top: 15px;

        a {
          padding-left: 10px;
          padding-right: 0;
        }
      }

      .pages {
        float: none;
        display: block;
        width: 100%;
      }

      .page-link {
        font-size: 9px;
        padding-right: 0;
        padding-bottom: 0px;
        padding-top: 10px;
        display: block;
      }

      .page-link:first-of-type {
        padding-top: 0;
      }

    }

    .contact .brand-info img {
      margin-top: 0;
      min-width: 100%;
      max-width: 100%;
      display: inline-block;
    }
    .contact .brand-image {
      text-align: right;
    }

    .contact .tel {
      margin-top: 40px;
    }
    .contact .contact-button {
      margin-left: 15px;
      margin-right: 15px;
    }
    .about .related {
      margin-top: 15px;
    }
  }

}