
#product {

  .needhelp {
    background: #fff;

    display: none;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
      display: block;
      margin-bottom: 25px;

      img {
        margin: 0 auto;
      }
    }
  }

  .overview {
    position: relative;
    background: #fff;
    color: #000;
    padding-bottom: 25px;

    .image {

      margin-top: 25px;
      margin-left: 25px;
      position: relative;

      @media (max-width: $screen-sm-max) {
        margin-left: 0px;
      }



      .viewer {
        text-align: center;
        background: #fff;
        border: 1px solid #eee;

        img {
          margin: 0 auto;
          display: inline-block;
        }

        .item {
          padding: 40px;
        }

        .carousel-indicators {
          bottom: -60px;
          li {
            border-color: #DEDEDE;
            background: #DEDEDE;
          }
          .active {
            background: #8D8D8D;
          }


          @media (max-width: $screen-sm-max) {
            bottom: -8px;
          }

          @media (min-width: $screen-sm-min) {
            display: none;
          }
        }
      }

      .social {
        position: absolute;
        right: 13px;
        top: 10px;
        z-index: 70;

        img {
          margin-bottom: 5px;
        }
      }

      .corner {
        right: initial;
        left: 14px;
        top: 10px;
        z-index: 700;
      }

      .images {
        padding-top: 15px;
        @include clearfix();
      }

      .badges {
        position: relative;
        padding-top: 10px;
      }

    }



    .thumbnails {
      position: relative;
      .thumbnail {
        float: left;
        cursor: pointer;
        border-color: #eee;
        background: #fff;
        padding: 5px;
        display: block;

        margin-right: 9px;
        height: 72px;
        width: 72px;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          max-height: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%)
        }

        .glyphicon-plus {
          margin-top: 21px;
          margin-left: 22px;
        }
      }
      .active {
        border-color: $brand-primary;
      }
    }




    .details {
      padding: 25px 25px 0 0px;
      @media (max-width: $screen-xs-max) {
        padding: 13px;
      }

      .title {
        font-weight: 700;
        font-size: 40px;

        @media (max-width: $screen-xs-max) {
          margin-top: 0;
        }
      }

      .meta {
        margin-bottom: 50px;
      }

      .desc {
        a {
          font-weight: 700;
        }
      }

      .tagline {
        font-weight: 700;
        margin-bottom: 20px;
      }

      .bullets {
        margin-bottom: 15px;
        padding-left: 25px;

        li {
          display: block;
          padding-bottom: 10px;
        }

        li:before {
          content: "\e013";
          font-family: 'Glyphicons Halflings';
          font-size: 9px;
          float: left;
          margin-top: -2px;
          margin-left: -17px;
          color: #666;
        }
      }

      .price {
        text-align: right;
        font-size: 17px;
        margin-bottom:15px;
      }

      .buttons {
        text-align: right;

        .btn-primary {
          border-width: 2px;
          margin-right: -4px;
        }

        .add-favorites {
          margin-left: 15px;
          border-width: 1px;
          border-color: #000;
          color: #000;
          padding: 8px 12px;

          &:hover {
            background: #000;
            color: #fff;
          }
        }

      }
    }

    .disclaimer {
      padding-left: 25px;
      @media (max-width: $screen-xs-max) {
        padding: 13px;
        padding-bottom: 0;
      }
    }
  }

  .about {
    padding-top: 25px;


    .tabs {
      a {
        display: block;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: bold;
        background: #565656;
        color: #fff;
        text-decoration: none;
      }
      a.active {
        background: #fff;
        color: #000;
      }
      a:hover {
        text-decoration: none;
      }
      a, a:hover, a:active, a:focus {
        outline: 0;
      }

      .row {
        margin-left: -1px;
        margin-right: -1px;
      }
      .row > div {
        padding-left: 1px;
        padding-right: 1px;
      }
    }


    .tabs.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 49px;
      background: #262626;
      box-shadow: 0 0px 6px rgba(0,0,0,0.5);
      z-index: 80;
      max-width: 1280px;
      margin: 0 auto;
    }


    .about-content {
      .about-panel {
        background-color: #fff;
        color: #000;
        padding: 25px 50px;
        margin-bottom: 2px;

        @media (max-width: $screen-xs-max) {
          padding: 13px;
        }

        h3, .h3 {
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 35px;
          font-size: 30px;
          font-weight: bold;
          @media (max-width: $screen-xs-max) {
            margin-top: 20px;
            font-size: 25px;
          }
        }
      }

      .accordion-tab {
        display: none;
      }

      @media (max-width: $screen-xs-max) {
        .accordion-tab {
          display: block;
          font-size: 17px;
          padding: 16px 13px;
          color: #fff;
          background: $primary;
          text-transform: uppercase;
          text-decoration: none;
          //font-weight: 700;
          cursor: pointer;

          .caret {
            top: 5px;
          }
        }
        .accordion-tab:hover {
          text-decoration: none;
        }
        .accordion-tab.active {
          background: #000;
        }
        .about-panel {
          display: none;
        }
        .about-panel.active {
          display: block;
        }
      }
      @media (min-width: $screen-sm-min) {
        .about-panel {
          display: block !important;
        }
      }
    }

    #related {
      background: #353535;
      color: #fff;

      .more {
        font-weight: bold;
      }
      .product-blocks .product-blocks-items {
        padding-top: 25px;
        padding-bottom: 0;


        @media (max-width: $screen-xs-max) {
          padding: 0 !important;
        }
      }
    }

    #techdetails {
      background: #fff;

      .h4 {
        font-size: 17px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: -5px;
      }

      table {
        width: 100%;
        margin-left: -25px;
        border-spacing: 25px 0;
        border-collapse: separate;

        @media (max-width: $screen-xs-max) {
          margin-left: 0px;
          border-spacing: 0px 0;
          margin-bottom: 25px;
        }
      }

      td, th {
        padding: 8px 2px;
        width: 50%;
      }

      thead {
        th {
          border-top: 2px solid #000;
          border-bottom: 2px solid #000;
        }
      }

      tbody {
        td {
          border-bottom: 1px solid #000;
        }
      }

      tfoot {
        td {
          border-top: 1px solid #000;
        }
      }


      ul.downloads {

        li {
          padding-bottom: 10px;
          line-height: 32px;
        }
        img {
          padding-right: 10px;
        }
        a {
          color: #000;
        }
        a:hover {
          text-decoration: none;
        }
      }


    }

    .services {
      background: #000 !important;
      color: #fff !important;
      background-repeat: no-repeat;
      background-image: url('/design/img/jts/product-service-bg.jpg');
      background-size: cover !important;

      .item {
        height: 310px;
        margin-top: -25px;
        margin-bottom: -25px;
        text-align: center;

        .contact {
          padding-top: 25px;
          line-height: 25px;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }

        @media (max-width: $screen-xs-max) {
          margin: 0 -15px 15px -15px;
          height: 320px;
          padding: 0;

          .contact {
            padding-top: 0;
          }
        }
      }

      @media (min-width: $screen-xs-max) {
        .item:hover {
          margin-top: -35px;
          padding-bottom: 60px;
          background: #000;
          color: #fff;
          height: 320px;
        }
      }
    } // .services

  } // .about

}// #product

#product.brand-monacor {
  #imageviewer:after {
    position: absolute;
    display: block;
    width: 70px;
    height: 43px;
    z-index: 2;
    background: url('/design/img/monacor.png') no-repeat top left;
    background-size: contain;
    content: " ";
    bottom: 10px;
    right: 10px;
  }
}


#merkliste .item-monacor {
  .thumbnail {
    position: relative;
  }
  .thumbnail:after {
    position: absolute;
    display: block;
    width: 35px;
    height: 22px;
    z-index: 2;
    background: url('/design/img/monacor.png') no-repeat top left;
    background-size: contain;
    content: " ";
    bottom: 5px;
    right: 5px;
  }
}